import React from "react";
import s from './NextButton.module.css'
import { Link } from "react-router-dom";

export default function NextButton({title, style, onClick, href, target}){
    return(
        <Link className={s.button} style={style} onClick={onClick? () => onClick(): ()=>{}} to={href} target={target}>
            <span className={s.circle} aria-hidden="true">
                <span className={s.arrow}></span>
            </span>
            <span className={s.title}>{title}</span>
        </Link>
    )
}