import React, {useState, useRef, useEffect, createRef} from "react";
import s from './ProjectsCarousel.module.css'
import NextButton from "../Buttons/NextButton/NextButton";
import host from "../../api/host";

export default function ProjectsCarousel({data}){
    const [items, setItems] = useState([...data])
    const [isDragged, setDragged] = useState(false)
    const [isClicked, setClicked] = useState(false)
    const carousel = useRef()
    const itemsRefs = useRef(data.map(() => createRef()))
    const [currentItem, setItem] = useState(0)
    const [carPosition, setPosition] = useState(0)

    useEffect(() => {
        setItems([...data])
        const refs = []
        data.map(() => refs.push(createRef()))
        itemsRefs.current = refs
    }, [data])

    useEffect(() => {
        setItem(0)
        items.map((item, index) => {
            item.el = itemsRefs.current[index]?.current
        })
        if(items[0]){
            let el = items[0].el
            if(el){
                items[0].el.style.width = '500px'
                items[0].el.style.height = '600px'
            }
        }
    }, [itemsRefs.current])

    useEffect(() => {
        if(!isDragged){
            items.map((item, index) => {
                let style = item.el?.style;
                if(index === currentItem && style){
                    style.width = '500px'
                    style.height = '600px'
                }
                else{
                    if(style){
                        style.width = ''
                        style.height = ''
                        style.transition = ''
                    }
                }
            })
            carousel.current.style.scrollBehavior = 'smooth'
            carousel.current.scrollLeft = 224 * currentItem
        }
    }, [currentItem, isDragged])

    const dragMove = (e) => {
        if(isDragged){
            setClicked(false)
            carousel.current.scrollLeft = carPosition - e.clientX
            let current
            items.map((item, index) => {
                if(item.el.offsetLeft <= carousel.current.scrollLeft + 300){
                    current = index
                }
            })
            setItem(current)
            getItemsSize()
        }
    }
    const dragStart = (e) => {
        e.preventDefault()
        setClicked(true)
        setDragged(true)
        carousel.current.style.scrollBehavior = ''
        setPosition(carousel.current.scrollLeft + e.clientX)
    }
    const dragEnd = (e) => {
        if(isDragged){
            e.preventDefault()
            setDragged(false)
            if(isClicked) setItem(Number(e.target.id))
        }
    }

    const getItemsSize = () => {
        if(carousel.current){
            const layout = carousel.current
            let center = layout.scrollLeft
            items.map((item, index) => {
                let offset = Math.abs(item.el.offsetLeft - center) / 200,
                    style = item.el.style;
                if(index <= currentItem + 1 && index >= currentItem - 1){
                    style.width = `${Math.floor(500 / offset)}px`
                    style.height = `${Math.floor(600 / offset)}px`
                    style.transition = ''
                }
                else{
                    style.width = ''
                    style.height = ''
                    style.transition = ''
                }
            })
        }
    }
    return(
        <section className={s.main}>
            <>
                <div className={s.carousel}>
                    <div
                        className={s.items}
                        ref={carousel}
                        onMouseDown={(e) => dragStart(e)}
                        onMouseMove={(e) => dragMove(e)}
                        onMouseUp={(e) => dragEnd(e)}
                        onMouseLeave={(e) => dragEnd(e)}
                    >
                        {data?.map((item, index) => {
                            return(
                                <div
                                    key={index}
                                    className={`${s.item} ${index === currentItem? s.active: ''}`}
                                    ref={itemsRefs.current[index]}
                                >
                                    <img
                                        draggable="false"
                                        src={`${host}${item.image}`}
                                        id={index}
                                        alt=""
                                    />
                                    {/* <iframe width="100%" height="100%" src={item.link}></iframe> */}
                                </div>
                            )
                        })}
                        <div className={s.empty}></div>
                    </div>
                    {
                        data[currentItem]?
                        <div className={`${s.info} ${isDragged? s.hidden: ''}`}>
                            <h2>{data[currentItem].name}</h2>
                            <p>{data[currentItem].description}</p>
                            <NextButton title={'Подробнее'} style={{marginTop: '20px'}} target="blank" href={`${data[currentItem].link}`}/>
                        </div>
                        :
                        ""
                    }
                </div>
            </>
        </section>
    )
}