import React, {useState, useEffect} from "react";
import s from './ProjectsPage.module.css'
import API from '../../api/api'
import {projectss} from '../../data/projects'
import ProjectsCarousel from "../../components/ProjectsCarousel/ProjectsCarousel";

export default function ProjectsPage(){
    const [projects, setProjects] = useState([])

    useEffect(() => {
        API.post(`get_projects/`)
        .then(res => {
            if(res.data?.length > 0){
                setProjects(res.data)
            }
            else{
                console.log(res)
            }
        })
    }, [])
    return(
        <main className="page">
            {
                projects? 
                <div className={s.content}>
                    <h1>Проекты</h1>
                    <ProjectsCarousel data={projects}/>
                </div>
                :
                ""
            }
        </main>
    )
}