import React from "react";
import flower from '../../../static/images/flower-svgrepo-com.svg'
import s from './ThemeToggler.module.css'

export default function ThemeToggler({state, onClick}){
    return(
        <div className={s.block} onClick={() => onClick()}>
            <div className={`${s.toggler} ${state? s.active: ''}`}>
                <img src={flower} alt=""/>
                <div className={s.background}/>
            </div>
        </div>
    )
}