import HomePage from "../pages/HomePage/HomePage"
import ProjectsPage from "../pages/ProjectsPage/ProjectsPage"

const home = <HomePage/>
const projects = <ProjectsPage/>
const about = <section className="page"><h1>О себе</h1></section>

const menu_data = [
    {url: "/", title: "Главная", element: home},
    {url: "/projects", title: "Проекты", element: projects},
    {url: '/about', title: 'О себе', element: about},
]
export default menu_data