import React from "react";
import s from './Tags.module.css'

export default function Tags({tags, style}){
    return(
        <section className={s.tags} style={style}>
            {tags?.map((tag, index) => {
                return(
                    <div className={s.tag} key={index}>{tag.name}</div>
                )
            })}
        </section>
    )
}