import React, { useContext } from "react";
import { MenuContext } from "../../../contexts/MenuContext";
import s from "./Header.module.css"
import { Link } from "react-router-dom";
import menu_data from "../../../routes/routes";
import { useLocation } from "react-router-dom";

export default function Header({children, next, prev}){
    const [menu, setMenu] = useContext(MenuContext)
    const location = useLocation()
    
    return(
        <header className={`${s.header} ${menu? s.open: ''}`}>
            <menu className={s.menu}>
                {menu_data?.map((item, index) => {
                    return(
                        <Link className={item.url === location.pathname? s.active_link: ''} to={item.url} key={index}>{item.title}</Link>
                    )
                })}
            </menu>
            <div className={s.controls}>
                <Link className={s.arrow} to={prev}>&and;</Link>
                <Link className={s.arrow} to={next}>&or;</Link>
            </div>
            {children}
        </header>
    )
}