import React, { useState, useEffect } from "react";
import s from "./Layout.module.css"
import Header from "./Header/Header";
import Body from "./Body/Body";
import { MenuContext } from "../../contexts/MenuContext";
import MenuButton from "../Buttons/MenuButton/MenuButton";
import { useLocation, useNavigate } from "react-router";
import menu_data from "../../routes/routes";
import backgroundImage from "../../static/images/background.jpg"
import ThemeToggler from "../Buttons/ThemeToggler/ThemeToggler";

export default function Layout(){
    const [menu, setMenu] = useState(false)
    const [background, setBackground] = useState(false)
    const location = useLocation()
    const [stopEvents, setStopEvents] = useState(false)
    let current = menu_data.map(item => item.url).indexOf(location.pathname)
    const [next, setNext] = useState(current+1 < menu_data.length? menu_data[current+1].url: menu_data[0].url)
    const [prev, setPrev] = useState(current-1 >= 0? menu_data[current-1].url: menu_data[menu_data.length-1].url)
    const navigate = useNavigate()
    
    const controlDirection = (e) => {
        if(!stopEvents){
            if(e.deltaY > 0) {
                navigate(next)
            } else {
                navigate(prev)
            }
        }
    }

    useEffect(() => {
        let current = menu_data.map(item => item.url).indexOf(location.pathname)
        setNext(current+1 < menu_data.length? menu_data[current+1].url: '')
        setPrev(current-1 >= 0? menu_data[current-1].url: menu_data[menu_data.length-1].url)
    }, [location])
    return(
        <MenuContext.Provider value={[menu, setMenu]}>
            <div className={`${s.layout} ${menu? s.menu_mode:''} ${background? s.solid_mode: ''}`} onWheel={(e) => controlDirection(e)} style={{background: `url(${backgroundImage})`}}>
                <div className={s.header}>
                    <Header next={next} prev={prev}>
                        <MenuButton menu={menu} setMenu={setMenu}/>
                        <ThemeToggler state={background} onClick={() => setBackground(!background)}/>
                    </Header>
                </div>
                <div className={s.body}><Body stopEvents={stopEvents} setStopEvents={(state) => setStopEvents(state)}/></div>
                <div className={s.footer}></div>
            </div>
        </MenuContext.Provider>
    )
}