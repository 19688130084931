const skills = [
    {name: 'HTML'},
    {name: 'CSS'},
    {name: 'SCSS'},
    {name: 'JavaScript'},
    {name: 'React JS'},
    {name: 'Odoo ERP'},
    {name: 'Python'},
    {name: 'MVC'},
]

export {skills}