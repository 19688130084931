import React from "react";
import s from './HomePage.module.css'
import Avatar from "../../components/Git/Avatar/Avatar";
import Tags from "../../components/Tags/Tags";
import { skills } from "../../data/tags";
import NextButton from "../../components/Buttons/NextButton/NextButton";

export default function HomePage(){
    return(
        <main className='page'>
            <div className={s.content}>
                <section className={s.git}>
                    <div className={s.profile}>
                        <Avatar style={{minWidth: '150px', height: '150px'}}/>
                        <div className={s.info}>
                            <h1>Xorikita</h1>
                            <p>💬 Frontend developer</p>
                            <p>@sedrin880  •  Member since April 22, 2022</p>
                            <p>🕑10:22 AM</p>
                        </div>
                    </div>
                </section>
                <div className={s.description}>
                    <h1>Привет, я Константин!</h1>
                    <p>
                        Занимаюсь frontend разработкой веб приложений и сайтов с 2021 года.
                        Имею опыт работы с React js, Native js, Odoo ERP (как статика, так и PublicWidget + сниппеты).
                    </p>
                    <p>Навыки:</p>
                    <Tags tags={skills}/>
                    <NextButton title={'К проектам'} style={{marginTop: '20px'}} href={'/projects'}/>
                </div>
            </div>
        </main>
    )
}